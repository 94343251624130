
import './App.css'
import firebase from "firebase";
import 'firebase/firestore'
import 'firebase/auth'
import axios from 'axios'
// import fuzzysort from 'fuzzysort'

import { useAuthState } from "react-firebase-hooks/auth";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useState, useEffect} from "react";

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyAS0BY96T7fEyd2fSnXRcO1ckDfBR-xCHw",
        authDomain: "nwmapps-1501181385818.firebaseapp.com",
        databaseURL: "https://nwmapps-1501181385818-default-rtdb.firebaseio.com",
        projectId: "nwmapps-1501181385818",
        storageBucket: "nwmapps-1501181385818.appspot.com",
        messagingSenderId: "743650249599",
        appId: "1:743650249599:web:84e45953b5e76711c5284a",
        measurementId: "G-HSCKK8MSHV"
    })
}else {
    firebase.app(); // if already initialized, use that one
}

const auth = firebase.auth()
const firestore = firebase.firestore()

const image_url = 'https://images.marinelink.com/images/storage'
const snippetTemplate = `{{url}}/{{size}}/{{filename}}

<amp-img
  alt="Alt"
  src="{{url}}/{{size}}/{{filename}}"
  width="{{width}}"
  height="{{height}}"
  layout="responsive"
></amp-img>
`

function SignIn() {
    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);
    }
    return (
        <button onClick={signInWithGoogle}>Sign In</button>
    )
}

function Profile(props) {
    const {uid, photoURL, displayName, email} = props.user;
    return auth.currentUser && (
        <div className={'profile'}>
            <img src={photoURL} alt={displayName}/>
            <div>
                {displayName}
                <div className={'small'}><em>{email}</em></div>
                <div className={'small'}>{uid}</div>
            </div>
            <button onClick={() => auth.signOut()}>Sign Out</button>
        </div>
    )
}

function ImageSnippets(props) {
    const {w, h, fn} = props.image; //uid, by,
    const [snippets, setSnippets] = useState('');
    const [size, setSize] = useState({width:100, height:100});
    useEffect(() => {
        const {width = 100, height = 100} = size;
        const zoom = Math.min(width / w, height / h);

        const newWidth = Math.min(w, Math.round(w * zoom));
        const newHeight = Math.min(h, Math.round(h * zoom));
        const newSize = `w${newWidth}h${newHeight}c`;
        const snippet = snippetTemplate.replaceAll('{{url}}', image_url)
            .replaceAll('{{filename}}', fn)
            .replaceAll('{{size}}', newSize)
            .replaceAll('{{width}}', `${newWidth}`)
            .replaceAll('{{height}}', `${newHeight}`)
        setSnippets(snippet)
    }, [size, w, h, fn, setSnippets]);
    const onChange = (e) => {
        setSize({...size, [e.target.name]:parseInt(e.target.value) || 100})
    }

    return (
        <>
            {fn && (
                <div className={'snippets-wrapper'}>
                    <div className={'controls'}>
                        <div>
                            <label>Width: </label>
                            <input name={'width'} defaultValue={100} onChange={onChange}/>
                        </div>
                        <div>
                            <label>Height: </label>
                            <input name={'height'} defaultValue={100} onChange={onChange}/>
                        </div>
                    </div>
                    <textarea value={snippets} readOnly />
                </div>
            )}
        </>
    )
}

/*
* cache-control: "private"
content-length: "1392"
content-type: "text/html; charset=utf-8"
filehash: "84C71FC964AD069CE86CAE2B4E936D15214BA8121D8F0ECA2C9862643E9805AD"
filenameurl: "maxresdefault1.jpg"
height: "1080"
isimage: "True"
width: "1920"
*  */

function LatestImage(props) {
    const {image, activeImage, setActiveImage} = props;
    const {uid, by, w, h, fn} = image;
    const setActive = () => setActiveImage(image)
    return (
        <>
            <div key={fn}>
                <img alt={`${by} - ${fn} - ${w} - ${h} - ${uid}`}
                     className={activeImage === image? 'active' : 'inactive'}
                     onClick={setActive}
                     src={`https://images.marinelink.com/images/storage/w200h200/${fn}`} />
            </div>
        </>
    )
}

function uploadHandler(e) {
    if(!e.target.files[0])
        return;

    const file = e.target.files[0];
    e.target.value = [];

    const formData = new FormData();
    formData.append("file", file);
    axios.post("https://storage20.marinelink.org/files/putfile", formData, {
    })
        .then(res => {
            const { width, height, filenameurl, isimage } = res.headers;
            const { uid, email } = auth.currentUser;
            const file = {
                w: parseInt(width),
                h: parseInt(height),
                fn: filenameurl,
                isImage: isimage.toLowerCase() === 'true',
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                by: email
            }
            const imagesRef = firestore.collection('images');
            imagesRef.doc(filenameurl)
                .set(file, { merge: true })
                .then(() => console.log('Saved'))
                .catch(console.error)
        })
}

function LatestImages() {
    const [images, loading, error] = useCollectionData(
        firestore.collection('images')
            .orderBy('createdAt', 'desc')
            .limit(1000)
        , {idField: 'id'});
    const [activeImage, setActiveImage] = useState();

    return (
        <>
            {loading && <div>Loading...</div>}
            {error && <div className={'error'}>Error: {JSON.stringify(error)}</div> }
            {images && (
                <div className={'wrapper'}>
                    {activeImage && <ImageSnippets image={activeImage} />}
                    <div className={'list'}>
                        {images && images.map(img => <LatestImage key={img.id} setActiveImage={setActiveImage} activeImage={activeImage} image={img}/>)}
                    </div>
                </div>
            )}
        </>
    )
}

function App() {
    const [user] = useAuthState(auth)

    return (
        <div className="App">
            <header className="App-header">
                {user ? <div className={'upload'}><input type={'file'} onChange={uploadHandler}/></div> : null}
                {user ? <Profile user={user}/> : null}
            </header>
            <section>
                {user ? <LatestImages /> : <SignIn/>}
            </section>
        </div>
    );
}

export default App;
